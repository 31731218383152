import React from 'react'
import Icon from './icon'

export default function Social({ icons, links }) {
  return (
    icons && (
      <ul className="flex gap-2">
        {icons.map((icon, index) => (
          <li key={index}>
            <Icon {...icon} />
          </li>
        ))}
      </ul>
    )
  )
}
