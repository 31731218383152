import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

export default function Link({ url, title, target, children, ...props }) {
  if (url && url.startsWith('/')) {
    return (
      <GatsbyLink to={url} target={target || null} {...props}>
        {children ?? title}
      </GatsbyLink>
    )
  } else if (url) {
    url = url.replace(/^(https?:\/\/)wp\./, '$1').replace('\.tasty\.studio', '')

    return (
      <a href={url} target={target || null} {...props}>
        {children ?? title}
      </a>
    )
  }
  return <span {...props}>{children}</span>
}
