import React from 'react'
import Image from './image'
import Link from './link'

export function Logo({ image, link, size }) {
  if (!size) {
    throw new Error('<Logo> error: The `size` prop is required.')
  }

  const className = {
    sm: 'max-w-[4rem]',
    md: 'max-w-[6rem]',
    lg: 'max-w-[8rem] md:max-w-[11rem]'
  }[size]

  return link ? (
    <Link {...link}>
      <Image className={className} {...image} />
    </Link>
  ) : (
    <Image className={className} {...image} />
  )
}

export default function Logos({ logos, size }) {
  return (
    logos?.length && (
      <ul className="flex flex-wrap gap-12 justify-between items-center opacity-70 filter brightness-0 invert">
        {logos.map((logo, index) => {
          return (
            <li key={index}>
              <Logo size={size} {...logo} />
            </li>
          )
        })}
      </ul>
    )
  )
}
