import React from 'react'

export default function Image({ sourceUrl, srcSet, altText, ...attrs }) {
  const wpUrl = process.env.GATSBY_WP_URL
  const site =
    {
      420: '420expo'
    }[process.env.GATSBY_SITE] ?? process.env.GATSBY_SITE
  const src = wpUrl.endsWith('.test')
    ? sourceUrl
    : sourceUrl?.replace(wpUrl, `https://${site}.b-cdn.net`)
  const srcset = wpUrl.endsWith('.test')
    ? srcSet
    : srcSet?.replaceAll(wpUrl, `https://${site}.b-cdn.net`)

  return <img src={src} srcSet={srcset} alt={altText} {...attrs} />
}
