import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import {
  faBars,
  faGlobe,
  faMagnifyingGlass,
  faX
} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import Link from './link'

export default function Icon({ link, name }) {
  const icon = {
    faBars,
    faGlobe,
    faFacebook,
    faInstagram,
    faMagnifyingGlass,
    faTiktok,
    faTwitter,
    faYoutube,
    faX
  }[`fa-${name}`.replace(/-./g, (x) => x[1].toUpperCase())]

  return link ? (
    <Link {...link}>
      <FontAwesomeIcon icon={icon} />
    </Link>
  ) : (
    <FontAwesomeIcon icon={icon} />
  )
}
