import classNames from 'classnames'
import React from 'react'

export default function Container({ className, children, ...props }) {
  return (
    <div
      className={classNames('xxx-container-centered', className)}
      {...props}>
      {children}
    </div>
  )
}
