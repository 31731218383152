import classNames from 'classnames'
import React from 'react'

export default function Grid({ className, children }) {
  return (
    <div className={classNames('xxx-grid', className)}>
      {children}
    </div>
  )
}
