import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Link from './link'
import Logos from './logos'
import Social from './social'
import Container from './container'
import Grid from '../components/grid'

export default function Footer() {
  const {
    wp: {
      globals: {
        content: { footer }
      }
    }
  } = useStaticQuery(graphql`
    query {
      wp {
        globals {
          content {
            footer {
              menus {
                heading
                links {
                  link {
                    ...Link
                  }
                }
              }
              sponsors {
                primary {
                  logos {
                    image {
                      ...Image
                    }
                    link {
                      ...Link
                    }
                  }
                }
                secondary {
                  logos {
                    image {
                      ...Image
                    }
                    link {
                      ...Link
                    }
                  }
                }
                tertiary {
                  logos {
                    image {
                      ...Image
                    }
                    link {
                      ...Link
                    }
                  }
                }
                quaternary {
                  logos {
                    image {
                      ...Image
                    }
                    link {
                      ...Link
                    }
                  }
                }
              }
              extras {
                copyright
                form
                social {
                  icons {
                    name
                    link {
                      ...Link
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { menus, sponsors, extras } = footer
  const { primary, secondary, tertiary, quaternary } = sponsors ?? {}
  const { copyright, form, social } = extras ?? {}

  const [html, setHtml] = useState(form)

  async function onSubmit(event) {
    event.preventDefault()

    const { target: form } = event
    const id = form.id.replace(/[^\d]+/, '')
    const formData = new FormData(form)

    formData.set('action', 'form')

    const response = await fetch(
      `${process.env.GATSBY_WP_URL}/wp-admin/admin-ajax.php`,
      {
        method: 'post',
        body: formData
      }
    )

    const text = await response.text()
    const el = document.createElement('html')

    el.innerHTML = text

    setHtml(el.querySelector('body').innerHTML)
    el.remove()

    window[`gf_submitting_${id}`] = false
  }

  return (
    <footer>
      <div className="420:border-primary exxxotica:border-tertiary border-b w-full"></div>
      <div className="bg-secondary text-primary">
        <Container className="pt-8">
          <Grid className="py-16">
            {menus && (
              <nav className="col-span-12 md:col-span-8">
                <ul className="grid grid-cols-8 gap-8">
                  {menus.map(({ heading, links }, key) => (
                    <li className="col-span-4 lg:col-span-2" key={key}>
                      {heading && <div className="heading-tiny">{heading}</div>}
                      {links && (
                        <ul className="mt-2">
                          {links.map(({ link }, key) => (
                            <li className="control-xs my-4" key={key}>
                              <Link
                                className="text-primary hover:text-tertiary duration-200 ease-in-out"
                                {...link}
                              />
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            )}
            {form && (
              <>
                <div className="col-span-12 mt-6 md:col-span-4 md:mt-0">
                  <div className="heading-tiny">Newsletter</div>
                  <div
                    className="footer_newsletter mt-4 w-full"
                    onSubmit={onSubmit}
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>
              </>
            )}
          </Grid>
        </Container>
      </div>
      <div className="420:bg-quinary exxxotica:bg-secondary text-primary pb-12">
        <Container>
          <div className="420:border-none exxxotica:border-tertiary flex flex-col justify-between border-t pt-8 md:flex-row md:items-center">
            <div className="control-xs">
              {copyright && (
                <p>{copyright.replace('{year}', new Date().getFullYear())}</p>
              )}
            </div>
            {social.icons && (
              <div className="mt-4 md:mt-0">
                <Social {...social} />
              </div>
            )}
          </div>
          {primary.logos && (
            <div className="mt-16">
              <Logos size="lg" {...primary} />
            </div>
          )}
          {secondary.logos && (
            <div className="mt-12">
              <Logos size="md" {...secondary} />
            </div>
          )}
          {tertiary.logos && (
            <div className="mt-12">
              <Logos size="sm" {...tertiary} />
            </div>
          )}
          {quaternary.logos && (
            <div className="mt-16">
              <div className="heading-tiny mb-8 text-center">Past sponsors</div>
              <Logos size="sm" {...quaternary} />
            </div>
          )}
        </Container>
      </div>
    </footer>
  )
}
