export function environment(environments) {
  const environment = process.env.NODE_ENV

  if (!environments) {
    return environment
  }

  if (!(environments instanceof Array)) {
    environments = [environments]
  }

  return environments.includes(environment)
}

export function site(sites) {
  const site = process.env.GATSBY_SITE

  if (!sites) {
    return site
  }

  if (!(sites instanceof Array)) {
    sites = [sites]
  }

  return sites.includes(site)
}

export function env(key, defaultVal = null) {
  return process.env[key] ?? defaultVal
}
