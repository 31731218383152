import classNames from 'classnames'
import React from 'react'
import Link from './link'

export default function Button({
  color,
  size,
  outline = false,
  className,
  children,
  ...props
}) {
  if (!color) {
    throw new Error('<Button> error: The `color` prop is required.')
  }

  if (!size) {
    throw new Error('<Button> error: The `size` prop is required.')
  }

  const classes = [
    'button'
  ]

  classes.push(
    {
      black: [
        'button-dark',
        'button-dark-outline'
      ],
      white: [
        'button-light',
        'button-light-outline'
      ]
    }[color][+outline]
  )

  classes.push(
    {
      sm: 'button-sm',
      md: 'button-md',
      lg: 'button-lg'
    }[size]
  )

  return (
    <Link className={classNames(classes.join(' '), className)} {...props} />
  )
}
