import { Dialog } from '@headlessui/react'
import { useLocation } from '@reach/router'
import Fuse from 'fuse.js'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import Button from './button'
import Icon from './icon'
import Image from './image'
import Link from './link'
import Container from './container'

export default function Header() {
  const {
    wp: {
      globals: {
        content: { header }
      }
    },
    allWpContentNode: { nodes }
  } = useStaticQuery(graphql`
    fragment Title on WpNodeWithTitle {
      title
    }

    query {
      wp {
        globals {
          content {
            header {
              media {
                logo {
                  ...Image
                }
              }
              menus {
                primary {
                  cta {
                    ...Link
                  }
                  links {
                    link {
                      ...Link
                    }
                  }
                }
                secondary {
                  links {
                    link {
                      ...Link
                    }
                  }
                }
              }
            }
          }
        }
      }
      allWpContentNode(
        filter: {
          contentTypeName: {
            in: [
              "appearance"
              "article"
              "exhibitor"
              "location"
              "page"
              "seminar"
              "show"
            ]
          }
        }
      ) {
        nodes {
          ...Title
          id
          uri
          contentType {
            node {
              label
            }
          }
        }
      }
    }
  `)

  const { media, menus } = header
  const { logo } = media ?? {}
  const { primary, secondary } = menus ?? {}

  const location = useLocation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const mobileInput = useRef(null)
  const desktopInput = useRef(null)
  const [searchOpen, setSearchOpen] = useState(false)
  const [query, setQuery] = useState('')

  function toggleSearch() {
    setSearchOpen(!searchOpen)
  }

  useEffect(() => {
    if (searchOpen) {
      if (mobileInput.current.offsetParent !== null) {
        mobileInput.current.focus()
      } else if (desktopInput.current.offsetParent !== null) {
        desktopInput.current.focus()
      }
    }
  }, [searchOpen])

  let results = [...nodes]

  if (query) {
    results = new Fuse(results, {
      keys: ['title'],
      threshold: 0.25
    })
      .search(query)
      .map(({ item }) => item)
  }

  const groupedResults = {}

  for (const result of results) {
    const label = result.contentType.node.label

    if (!groupedResults[label]) {
      groupedResults[label] = []
    }

    groupedResults[label].push(result)
  }

  Object.values(groupedResults).forEach((items) => items.splice(5))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setMobileMenuOpen(false)
  }, [location])

  return (
    <header className="relative isolate z-30 flex flex-col">
      {(primary.cta || primary.links) && (
        <nav className="420:border-none exxxotica:border-tertiary bg-secondary exxotica:border-b order-2 py-6">
          <Container className="flex items-center justify-between">
            <Link url="/">
              {logo && <Image className="w-32 md:w-44" {...logo} />}
            </Link>
            <div className="text-primary flex gap-x-8 lg:hidden">
              <button type="button" className="block" onClick={toggleSearch}>
                <Icon color="white" name="magnifying-glass" />
              </button>
              <button type="button" onClick={() => setMobileMenuOpen(true)}>
                <Icon color="white" name="bars" />
              </button>
            </div>
            <ul className="hidden lg:flex lg:items-center">
              {primary.links.map(({ link }, key) => (
                <li className="ml-12" key={key}>
                  <Link
                    className="control-lg text-primary hover:text-tertiary duration-200 ease-in-out"
                    {...link}
                  />
                </li>
              ))}
              {primary.cta && (
                <li className="ml-6">
                  <Button size="md" color="white" outline {...primary.cta} />
                </li>
              )}
            </ul>
          </Container>
        </nav>
      )}
      {secondary.links && (
        <nav className="420:border-none exxxotica:border-tertiary exxxotica:bg-secondary 420:bg-quinary control-sm exxxotica:border-b relative order-1 hidden py-5 lg:block">
          <Container className="flex items-center justify-end">
            <ul className="flex">
              {secondary.links.map(({ link }, key) => (
                <li className="ml-8" key={key}>
                  <Link
                    className="text-primary hover:text-tertiary duration-200 ease-in-out"
                    {...link}
                  />
                </li>
              ))}
            </ul>
            <div
              className={`duration-300 ease-in-out ${
                searchOpen ? 'w-64' : 'w-0'
              }`}>
              <div className="pl-8">
                <input
                  ref={desktopInput}
                  value={query}
                  onInput={({ target: { value } }) => setQuery(value)}
                  className="search-input w-full border-0 border-b-2 border-white bg-transparent py-2 text-white focus:outline-none focus:ring-0"
                />
                <div
                  className={`fixed inset-x-0 bottom-0 top-[74.5px]${
                    searchOpen ? '' : ' hidden'
                  }`}
                  onClick={() => setSearchOpen(false)}
                />
              </div>
            </div>
            <button
              type="button"
              className="ml-8 block text-white"
              onClick={toggleSearch}>
              <Icon color="white" name="magnifying-glass" />
            </button>
            {searchOpen && query && (
              <div className="relative self-end">
                <div className="exxxotica:translate-y-px absolute right-full top-full mt-5">
                  <div className="rounded-b-xxx exxxotica:translate-y-px border-secondary max-h-64 w-80 overflow-hidden overflow-y-scroll border-x-2 border-b-2 bg-white">
                    <div>
                      {results?.length ? (
                        <dl>
                          {Object.entries(groupedResults).map(
                            ([type, nodes]) => (
                              <div key={type} className="relative">
                                <dt className="bg-secondary sticky top-0 px-6 py-3 text-white">
                                  {type}
                                </dt>
                                {nodes?.length &&
                                  nodes.map(({ id, title, uri }) => (
                                    <dd
                                      key={id}
                                      className="first-of-type:mt-2 last-of-type:pb-4">
                                      <Link
                                        onClick={() => setSearchOpen(false)}
                                        url={uri}
                                        className="hover:bg-secondary/10 block px-6 py-4 normal-case text-black">
                                        {title}
                                      </Link>
                                    </dd>
                                  ))}
                              </div>
                            )
                          )}
                        </dl>
                      ) : (
                        <p className="px-8 py-8 normal-case text-black">
                          No results found.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="rounded-b-xxx border-tertiary 420:hidden pointer-events-none absolute inset-x-px inset-y-0 border-x border-b [clip-path:rect(0_100%_92px_0)]" />
                </div>
              </div>
            )}
          </Container>
        </nav>
      )}
      {searchOpen && (
        <div className="absolute left-0 top-full w-full lg:hidden">
          <div className="fixed inset-0" onClick={() => setSearchOpen(false)} />
          <div className="bg-secondary relative px-8">
            <input
              ref={mobileInput}
              value={query}
              onInput={({ target: { value } }) => setQuery(value)}
              placeholder="Search..."
              className="search-input w-full border-0 border-b-2 border-white bg-transparent py-2 text-white focus:outline-none focus:ring-0"
            />
            <div className="bg-secondary h-4" />
          </div>
          {searchOpen && query && (
            <div className="rounded-b-xxx border-secondary relative max-h-64 w-full overflow-hidden overflow-y-scroll border-x-2 border-b-2 bg-white lg:hidden">
              {results?.length ? (
                <dl>
                  {Object.entries(groupedResults).map(([type, nodes]) => (
                    <div key={type} className="relative">
                      <dt className="bg-secondary sticky top-0 px-8 py-3 text-white">
                        {type}
                      </dt>
                      {nodes?.length &&
                        nodes.map(({ id, title, uri }) => (
                          <dd
                            key={id}
                            className="first-of-type:mt-2 last-of-type:pb-4">
                            <Link
                              onClick={() => setSearchOpen(false)}
                              url={uri}
                              className="hover:bg-secondary/10 block px-8 py-4 normal-case text-black">
                              {title}
                            </Link>
                          </dd>
                        ))}
                    </div>
                  ))}
                </dl>
              ) : (
                <p className="px-8 py-8 normal-case text-black">
                  No results found.
                </p>
              )}
            </div>
          )}
        </div>
      )}
      <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <Dialog.Panel
          focus="true"
          className="bg-secondary fixed inset-0 z-20 overflow-scroll">
          <Container className="bg-secondary 420:border-primary exxxotica:border-tertiary border-b">
            <div className="flex items-center justify-between py-6">
              <Link url="/">
                {logo && <Image className="w-32 md:w-44" {...logo} />}
              </Link>
              <button
                type="button"
                className="text-primary"
                onClick={() => setMobileMenuOpen(false)}>
                <Icon name="x" />
              </button>
            </div>
          </Container>
          <Container className="w-full pt-8">
            {(primary.cta || primary.links) && (
              <nav>
                <ul>
                  {primary.links.map(({ link }, key) => (
                    <li className="py-4" key={key}>
                      <Link
                        className="control-xl--display text-primary hover:text-tertiary duration-200 ease-in-out"
                        {...link}
                      />
                    </li>
                  ))}
                  {primary.cta && (
                    <li className="py-4">
                      <Link
                        className="control-xl--accent text-primary hover:text-tertiary duration-200 ease-in-out"
                        {...primary.cta}
                      />
                    </li>
                  )}
                </ul>
              </nav>
            )}
            {secondary.links && (
              <nav>
                <ul className="control-sm flex flex-col py-5">
                  {secondary.links.map(({ link }, key) => (
                    <li key={key}>
                      <Link
                        className="text-primary hover:text-tertiary inline-block py-4 duration-200 ease-in-out"
                        {...link}
                      />
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </Container>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
